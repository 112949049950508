import React from "react";
import "./MondayJune10th.css";
import { Text } from "@mantine/core";

const MondayJune10thAnimalResearch = () => {
  return (
    <div>
      <div className="mj10-list">
        <ul className="mj10-inner-list">
          <li>
            Chun-Jen Hsiao, Alexander Galazyuk - Depolarization shift in the
            resting membrane potential underlies hyperactivity of inferior
            colliculus neurons induced by an acoustic trauma.
          </li>
          <li>
            Chul Ho Jang, Gwang-Won Cho, Changjong Moon , Ilyong Park -
            Neuroprotective Effect of Valproic Acid on Salicylate-Induced
            Tinnitus
          </li>
          <li>
            Kyu-Yup Lee, Da-Jung Jung, Sang-Heun Lee - Study on protective
            effect of novel chemical compound against acquired hearing loss
          </li>
          <li>
            Jinsheng Zhang, Po Hung Chiang, Paul Morse, Tasnim Arroum, Maik
            Huttemann - Near-Infrared (NIR) Light Treatment of Tinnitus
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MondayJune10thAnimalResearch;
