import React from "react";
import { Text } from "@mantine/core";

const TuesdayHeterogeniety = () => {
  return (
    <div>
      <div className="mj10-list">
        <ul className="mj10-inner-list">
          <li>
            Mie Lærkegård Jørgensen,Torsten Dau, Tinnitus subgroups based on
            supra-threshold hearing characteristics
          </li>
          <li>
            Tanit Sanchez, Long term total remission of tinnitus: fact or myth?
          </li>
          <li>
            Gianfranco Velasco, Hyun Jae Cho, Young Jun Seo, Ji Hyung Lim, Jae
            Sang Han, Jae Hyun Seo, Shi Nae Park, The Size Analysis of Stapedius
            and Tensor Tympani Muscles in CT scan in Patients with Middle Ear
            Myoclonic Tinnitus
          </li>
          <li>
            Cosima F. Lukas, Daniela Ivansic, Gerlind Schneider, Orlando
            Guntinas-Lichius, Christian Dobel, Short and sweet: Long-lasting
            therapy success after interdisciplinary tinnitus treatment - a
            5-year follow-up study
          </li>
          <li>
            Phil Gander, What is the role of the hippocampus and parahippocampal
            gyrus in the persistence of tinnitus?
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TuesdayHeterogeniety;
