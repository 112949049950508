import { Text } from "@mantine/core";
import React from "react";

const TuesdayJune11thRound = () => {
  return (
    <div>
      <div className="mj10-host">
        <Text>
          <b>Moderator:</b> Dr Philippe Fournier
        </Text>
      </div>

      <Text className="mj10-text">
        <b>Panellists:</b> Panel: Myrian Westcott, Fatima Husain, Julia
        Campbell, David Eddins, Sylvie Hébert
      </Text>
      <Text className="mj10-text">
        Publications about hyperacusis and other sound tolerance disorders have
        grown exponentially in the last decade. Unfortunately, the definitions,
        criteria, and tools for diagnosis and management are still scarce. In
        this round table, experts will share their experience with patients
        presenting with such symptoms and how they proceed to study, diagnose
        and manage them. Attendees will be invited to join the discussion and
        ask questions.
      </Text>
    </div>
  );
};

export default TuesdayJune11thRound;
