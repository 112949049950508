import React from "react";
import { Flex, Text } from "@mantine/core";

const June12thMorning = () => {
  return (
    <div>
      <div className="mj10-host">
        <Text>
          <b>Chair:</b> Dr Sylvie Hébert, Professor and Program Director of
          Audiology, Université de Montréal
        </Text>
      </div>
      <Text className="mj10-text">
        <b>Summary:</b> Individuals with tinnitus often report that their
        tinnitus has been triggered by stress or that it is modulated by it. The
        evidence supporting these self-reports, however, is not very strong. In
        this talk, I will present an overview of the study findings on stress
        and tinnitus. More specifically, I will concentrate on the physiological
        correlates of stress, that is, the Hypothalamic-Pituitary-adrenal axis
        (HPA) and the Autonomic Nervous System. I will also present some new
        work from the laboratory that aims to expand the way the relationship
        between stress and tinnitus is studied
      </Text>
    </div>
  );
};

export default June12thMorning;
