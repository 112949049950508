import { Flex, Text } from "@mantine/core";
import React from "react";

const June12thPhysicalLinks = () => {
  return (
    <div>
      <div className="mj10-host">
        <Text>
          <b>Chair:</b> Sarah Michiels, Hasselt University, Belgium
        </Text>
      </div>

      <div className="mj10-list">
        <Text>SPEAKERS: </Text>
        <ol className="mj10-inner-list">
          <li>
            Mr. Robin Guillard, University of Grenoble Alpes, France - Tinnitus,
            dreams and awakening: new insights and theoretical implications
          </li>
          <li>
            Mr. Antonios Chalimourdas, Hasselt University, Belgium - Can
            Physical Activity Decrease the Tinnitus Severity? - A Longitudinal
            Observational Study 
          </li>
          <li>
            Ms. Sara Demoen, Hasselt University, Belgium - Health-related
            Quality of life in patients with somatic tinnitus: A cross sectional
            study 
          </li>
          <li>
            Dr. Annemarie van der Wal, ACTA Academic Center for Dentistry,
            Amsterdam, The Netherlands - The effect of orofacial treatment on
            tinnitus complaints in patients with somatosensory tinnitus 
          </li>
          <li>
            Prof. dr. Tanit Ganz Sanchez, University of Sao Paulo, ENT
            Department, School of Medicine, Brazil - Somatosensory tinnitus: who
            gets total remission?
          </li>
        </ol>
      </div>

      <Text className="mj10-text">
        <b>One Sentence Summary:</b> The symposium will bring together experts
        from audiology, neuroscience, and psychology to discuss sound tolerance
        disorders of loudness hyperacusis and misophonia in the context of
        tinnitus.
      </Text>
      <Text className="mj10-text">
        <b>Abstract:</b> Science shows us that several physical symptoms or
        dysfunctions may be present in patients with tinnitus. In this
        symposium, we aim to give a broad view of which physical symptoms might
        influence patients&#39; tinnitus and how we can treat them. Because
        physical symptoms are very common, also in people without tinnitus, the
        second aim of this symposium is to give insight into the prevalence of a
        series of physical symptoms in a tinnitus population compared to the
        general population. Treatment of the cervical spine or temporomandibular
        area is possible in many different ways and can have a positive effect
        on tinnitus severity. Therefore, the third aim of this symposium is to
        give insight into different therapies that can be applied in patients
        with somatosensory tinnitus and to show the effectiveness of these
        therapies on patients&#39; tinnitus severity. The other way around, we
        know several patients with somatosensory tinnitus who were cured of
        their tinnitus after cervical spine or temporomandibular treatment. The
        final goal of this symposium is to present the characteristics of these
        patients and the type of treatment that provided succor.
      </Text>
    </div>
  );
};

export default June12thPhysicalLinks;
