import React from "react";
import { Text } from "@mantine/core";

const WednesdayElectro = () => {
  return (
    <div>
      <div className="mj10-list">
        <ul className="mj10-inner-list">
          <li>
            Victoria Duda, Brandon Paul, Boutheina Jemel, Sylvie Hébert,
            Cortical Responses to Tinnitus-Like Stimuli: Electrophysiological
            Insights
          </li>
          <li>
            Emilie Cardon, Fien Aben, Laure Jacquemin, Vincent Van Rompaey, Marc
            J.W. Lammers, Olivier M.Vanderveken, Annick Gilles, P300 latency
            correlates with subjective tinnitus severity on a between-subjectand
            within-subject basis
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WednesdayElectro;
