import React from "react";
import { Text } from "@mantine/core";

const TuesdayPulsatile = () => {
  return (
    <div>
      <div className="mj10-list">
        <ul className="mj10-inner-list">
          <li>
            Nicole M. Cancelliere, Gurnish Sidora, Zainab Hussain, David
            Steinman, Vitor Mendes Pereira, Utilization of Transcanal Recordings
            for Objectification of Pulsatile Tinnitus
          </li>
          <li>
            Gurnish Sidora, Nicole Cancelliere, Anna Haley, Vitor Mendes
            Pereira, David Steinman, Understanding the Sound Production
            Mechanism of Pulsatile Tinnitus Using Computational Fluid Dynamics
          </li>
          <li>
            Minakshi Gupta, Yatin Mahajan, Pulsatile Tinnitus: A case report of
            assessment and management
          </li>
          <li>
            Jae‐Hyun Seo, Jae Sang Han, Shi Nae Park, The Application of
            Distortion Product Otoacoustic Emission for the objective
            measurement of Vascular Pulsatile Tinnitus
          </li>
          <li>
            Dave Korotkov - Living with Pulsatile Tinnitus: A Self-Narrative
            Using McAdam’s Levels of Personality Model
          </li>

          <li>
            Yoonjai Lee, Won Jun Noh, Jae-Jin Song, Forest for the Trees:
            Preliminary results of novel sigmoid sinus resurfacing in subjects
            with sigmoid sinus dehiscence and adjacent broad-based diverticulum
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TuesdayPulsatile;
